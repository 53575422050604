import store from '@/state/store'

export default [
    {
        path: '/',
        meta: { authRequired: false },
        name: 'home',
        component: () => import('./views/home'),
    },
    {
        path: '/about/team',
        meta:  { authRequired: false },
        name: 'Team',
        component: () => import('./views/about/team'),
    },
    {
        path: '/about/commands',
        meta: { authRequired: false },
        name: 'Discord Commands',
        component: () => import('./views/about/commands'),
    },
    {
        path: '/about/gdpr',
        name: 'gdpr',
        meta: { authRequired: false },
        component: () => import('./views/about/gdpr'),
        props: true,
    },
    {
        path: '/about/impress',
        name: 'impress',
        meta: { authRequired: false },
        component: () => import('./views/about/impress'),
        props: true,
    },
    {
        path: '/character/:charid',
        meta: { authRequired: false},
        name: 'character_info',
        component: () => import('./views/character/overview'),
        props: true,
    },
    {
        path: '/character/:charid/portrait',
        meta: { authRequired: false },
        name: 'character_portrait',
        component: () => import('./views/character/portrait'),
        props: true,
    },
    {
        path: '/character/:charid/classjobs',
        meta: { authRequired: false },
        name: 'character_classjobs',
        component: () => import('./views/character/classjobs'),
        props: true,
    },
    {
        path: '/freecompany/:fcid',
        meta: { authRequired:false },
        name: 'freecompany_overview',
        component: () => import('./views/freecompany/overview'),
        props: true,
    },
    {
        path: '/freecompany/:fcid/member',
        meta: { authRequired: false },
        name: 'freecompany_member',
        component: () => import('./views/freecompany/member'),
        props: true,
    },
    {
        path: '/linkshell/:lsid',
        meta: { authRequired: false },
        name: 'linkshell_index',
        component: () => import('./views/linkshell/index'),
        props: true,
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./views/account/login'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('./views/account/register'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('./views/account/forgot-password'),
        meta: {
            beforeResolve(routeTo, routeFrom, next) {
                // If the user is already logged in
                if (store.getters['auth/loggedIn']) {
                    // Redirect to the home page instead
                    next({ name: 'home' })
                } else {
                    // Continue to the login page
                    next()
                }
            },
        },
    },
    {
        path: '/logout',
        name: 'logout',
        meta: {
            authRequired: false,
            beforeResolve(routeTo, routeFrom, next) {
                store.dispatch('auth/logOut')
                const authRequiredOnPreviousRoute = routeFrom.matched.some(
                    (route) => route.push('/login')
                )
                // Navigate back to previous page, or home as a fallback
                next(authRequiredOnPreviousRoute ? { name: 'home' } : { ...routeFrom })
            },
        },
    },
    {
        path: '/calendar',
        name: 'Calendar',
        component: () => import('./views/calendar/index'),
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/news/ffxiv',
        name: 'FFXIV News',
        component: () => import('./views/news/ffxiv'),
        meta: { authRequired: false },
        props: true,
    },
    {
        path: '/news',
        name: 'News Overview',
        component: () => import('./views/news/overview'),
        meta: { authRequired: false },
        props: true,
    },
    {
        path: 'news/:newsid',
        name: 'News Topic',
        component: () => import('./views/news/topic'),
        meta: { authRequired: false },
        props: true,
    },
    {
        path: '/editor_demo',
        name: 'editor_demo',
        component: () => import('./views/forms/editor'),
        meta: { authRequired:false },
    },
    {
        path: '/starcitizen/mining',
        name: 'sc_mininginfo',
        component: () => import('./views/starcitizen'),
        meta: { authRequired: false },
        props: true
    },
    {
        path: '/xivapi',
        name: 'xivapi_home',
        component: () => import('./views/xivapi-laravel/home'),
        meta: { authRequired: false },
        props: true
    },
    {
        path: '/xivapi/search',
        name: 'xivapi_search',
        component: () => import('./views/xivapi-laravel/search'),
        meta: { authRequired: false },
        props: true
    },
    {
        path: '/xivapi/character',
        name: 'xivapi_character',
        component: () => import('./views/xivapi-laravel/character'),
        meta: { authRequired: false },
        props: true
    },
    {
        path: '*',
        name: 'Not Found',
        component: () => import('./views/errors/404'),
        meta: {
            authRequired: false,
        }
    },
]