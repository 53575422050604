import Vue from 'vue'
import App from './App'
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import router from './router/index'
import store from '@/state/store'
import VueSweetalert2 from 'vue-sweetalert2'
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import vco from "v-click-outside"
import "@/design/index.scss";
//const LightTheme = () => import("@/design/index-light.scss");
import i18n from './i18n'
import VueCookies from 'vue-cookies'

Vue.use(VueRouter)
Vue.use(BootstrapVue);
Vue.use(vco)
Vue.use(VueSweetalert2)
Vue.use(VueCookies)

console.log('Current Enviroment: ' + process.env.NODE_ENV);

if(!window.webpackHotUpdate) {
    Sentry.init({
        dsn: 'https://420dd2fdc34b4010b8d4320cc58c0810@o411702.ingest.sentry.io/5411891',
        integrations: [new VueIntegration({Vue, attachProps: true})],
        beforeSend(event) {
            if(event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id});
            }
            return event;
        }
    });
}

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')